import React,{useState} from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { connect, useDispatch } from 'react-redux';
import {
    loadingToggleAction,
    signupAction,
} from '../../store/actions/AuthActions';
// image
import logo from "../../images/logo-1.png";
import logoWhite from "../../images/logo-1.png";
import loginbg from "../../images/bg-login-1.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faPrint } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { UseAPI } from "../../services/UseAPI";

function Register(props) {
    const [email, setEmail] = useState('');
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [inputs, setInputs] = useState({
        nama_lengkap: '',
        tempat_lahir: '',
        tanggal_lahir: '',
        nik: '',
        phone_number: '',
        email: '',
        password: '',
    });

    const dispatch = useDispatch();

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs(values => ({...values, [name]: value}))
    }  
 
    function onSignUp(e) {
      //console.log(inputs);
          e.preventDefault();
        let error = false;
        const errorObj = [];
        Object.entries(inputs).forEach(([key,item])=>{
          if (item === '') {
              errorObj.push(key.replace('_',' ')+' wajib diisi');
              error = true;
          }
        })
        setErrors(errorObj);
        if (errorObj.length > 0) {
          swal('Oops', errorObj.join(', '), "error");
          return;
        }
        // //console.log(errorObj);
        // if (error) return;
        dispatch(loadingToggleAction(true));
        dispatch(signupAction(inputs, props.history));
    }

    const printCard = () => {
      console.log("printCard");
      Swal.fire({
        title: "Print Registration Card",
        text: "Type your NIK below",
        icon: "info",
        input: 'text',  // Insert an input element
        inputPlaceholder: "Type NIK here", // Placeholder for the input field
        showCancelButton: true,  // Option to cancel
        confirmButtonText: 'Submit',
        inputValidator: (value) => {
          if (!value) {
            return 'You need to enter a NIK!'
          }
        }
      }).then((result) => {
        if (result.isConfirmed) {
          UseAPI(`print-card`, { nik: result.value }, 'POST').then((resp) => {
            console.log("initial response useEffect", resp);
            if (resp.status === 'ok') {
              Swal.fire("Success", "Registration card printed successfully", "success"); // Show success message
            } else {
              Swal.fire("Error", resp.msg, "error"); // Show error message
            }
      
          });      
          // fetch('/api/print-card', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //   },
          //   body: JSON.stringify(data),
          // })
          // .then(response => response.json())
          // .then(data => {
          //   Swal.fire("Success", data.message, "success"); // Show success message
          // })
          // .catch((error) => {
          //   console.error('Error:', error);
          //   Swal.fire("Error", error.message, "error"); // Show error message
          // });
        }
      });
      
    }
  return (
    <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
    <div className="login-wrapper">
        <div className="login-aside-left" >
            <Link to={"#"} className="login-logo">
                <img src={logoWhite} alt="" className="ms-3" style={{height:"15vh"}}/>
              </Link>
            <div className="login-description">
                <h2 className="main-title mb-2">Registration</h2>
                <p className="">Complete your registration form</p>
                <div className="mt-5 bottom-privacy">
                    <Link to={"#"} className="">© 2024 Simut-ICT</Link>
                </div>
            </div>
        </div>
        <div className="login-aside-right">
            <div className="row m-0 justify-content-center h-100 align-items-center">
              <div className="p-5">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form-1">
                        {props.errorMessage && (
                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                {props.errorMessage}
                            </div>
                        )}
                        {props.successMessage && (
                            <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                {props.successMessage}
                            </div>
                        )}
                        <form onSubmit={onSignUp}>
                            <div className="form-group">
                                <label className="control-label">Nama Lengkap</label>
                                <input type="text" className="form-control h-auto"
                                  value={inputs.nama_lengkap}
                                   onChange={handleChange}
                                   placeholder="Nama Lengkap (tanpa gelar)"
                                   name="nama_lengkap"
                                   required
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Nomor NIK (Mahasiswa gunakan NIM)</label>
                                <input type="number" className="form-control h-auto"
                                  value={inputs.nik}
                                  onChange={handleChange}
                                   placeholder="Nomor NIK/NIM (Mahasiswa menggunakan NIM)"
                                   name="nik"
                                   required
                                />
                            </div>
                            <div className="row">
                                <label className="control-label">Tempat & Tanggal Lahir</label>
                                <div className="form-group col-md-8 col-6">
                                    <input type="text" className="form-control h-auto"
                                      value={inputs.tempat_lahir}
                                      onChange={handleChange}
                                       placeholder="Tempat Lahir"
                                       name="tempat_lahir"
                                       required
                                    />
                                </div>
                                <div className="form-group col-md-4 col-6">
                                    <input type="date" className="form-control h-auto"
                                      value={inputs.tanggal_lahir}
                                       onChange={handleChange}
                                       placeholder="Tanggal Lahir"
                                       name="tanggal_lahir"
                                       required
                                    />
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="control-label">Email</label>
                                <input type="email" className="form-control h-auto"
                                  value={inputs.email}
                                   onChange={handleChange}
                                   placeholder="Email"
                                   name="email"
                                   required
                                />
                            </div>
                            <div className="form-group">
                                <label className="control-label">Nomor WA</label>
                                <input type="number" className="form-control h-auto"
                                  value={inputs.phone_number}
                                   onChange={handleChange}
                                   placeholder="Nomor WA"
                                   name="phone_number"
                                   required
                                />
                            </div>
                            <div className="form-group position-relative">
                                <label className="control-label">Password</label>
                                <div className="input-group">
                                  <input type={showPassword ? "text" : "password"} className="form-control h-auto"
                                    value={inputs.password}
                                    onChange={handleChange}
                                    placeholder="Password"
                                    name="password"
                                    required
                                  />
                                  <div className="input-group-append" onClick={() => setShowPassword(!showPassword)}>
                                    <span className="input-group-text">
                                      <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                    </span>
                                  </div>
                                </div>
                            </div>
                          <div className="text-center mb-2">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Register
                            </button>
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              className="btn btn-warning btn-block"
                              onClick={()=>printCard()}
                            >
                              <FontAwesomeIcon icon={faPrint} /> Registration Card
                            </button>
                          </div>
                            <p className="mt-3">Have an account? <Link to="/login">Login</Link></p>
                        </form>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

  );
};

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);

